.team {
    padding-bottom: $section-padding;

    background-image: url('../../assets/img/page-background.jpg');
    background-size: cover;
    background-position: center;

    #mobile-card {
        height: 17vh;

        @include media-breakpoint-down(sm) {
            height: 25vh;
        }
    }

    #mobile-spacing {
        @include media-breakpoint-down(sm) {
            padding-top: 1.5rem;
        }
    }
}