.membership {
    padding-bottom: $section-padding;

    background-image: url("../../assets/img/page-background.jpg");
    background-size: cover;
    background-position: center;

    img {
        max-width: 80%;
        height: auto;

        @include media-breakpoint-up(sm) {
            max-width: 60%;
            height: auto;
        }

        @include media-breakpoint-down(sm) {
            max-width: 50%;
            height: auto;
        }
    }
}