.donation {
    padding-bottom: 1*$section-padding;

    background-image: url('../../assets/img/page-background.jpg');
    background-size: cover;
    background-position: center;

    img {
        width: 60%;
        height: auto;
    }

    @include media-breakpoint-down(sm) {
        padding-bottom: 0;
    }
}