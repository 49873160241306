nav {
    background-color: white;
    border-bottom: 1px solid rgb(212, 212, 212);

    img {
        width: 100%;
        max-width: 300px;
        height: auto;
    }

    .navbar-nav {

        .nav-link:not(.active) {
            color: $secondary;
            text-decoration: none;

            &:hover {
                color: $primary;
            }
        }
    }

    .cb-mail {
        font-size: small;
    }

    .fab {
        color: $primary;
    }

    .active {
        font-weight: bold;
        text-decoration: underline;
    }

    @include media-breakpoint-down(sm) {
        .cb {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        img {
            max-width: 200px;
        }
    }
}