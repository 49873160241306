header {
    background-image: url('../../../assets/img/background.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    height: 500px;
    color: white;
    background-position: 0px;

    @include media-breakpoint-down(sm) {
        background-image: url('../../../assets/img/background.jpg');
        background-size: cover;
        height: 400px;
        text-align: center;
    }

    .container {
        height: 100%;
        display: flex;
        align-items: center;
    }

    h1 {
        @include media-breakpoint-down(sm) {
            font-size: 3rem;
        }
    }

    p {
        @include media-breakpoint-down(sm) {
            font-size: 1.5rem;
        }
    }
}

.info {
    padding-top: $section-padding;
    padding-bottom: $section-padding;

    .info-img {
        background-image: url('../../../assets/img/notarzt.jpg');
        background-size: cover;
        background-position: center;
        height: 60vh;
        display: flex;
        align-items: center;
        justify-content: center;

        .img-wrapper {
            overflow: hidden;
            width: 100%;
            height: 100%;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    h5,
    h4,
    p {
        color: white;
    }
}