.about-us {
    background-image: url("../../assets/img/page-background.jpg");
    background-size: cover;
    background-position: center;

    i {
        color: $primary;
    }

    @include media-breakpoint-down(sm) {
        .call-to-action {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        p {
            text-align: center;
        }
    }
}