footer {
    margin-top: auto;
    padding: 20px 0;
    border-top: 1px solid rgb(212, 212, 212);

    a {
        text-decoration: none;

        &:hover {
            color: $primary;
        }
    }

    i {
        color: $primary;
    }

    .donator {
        display: flex;
        justify-content: center;
        align-items: center;

        .col-4 {
            width: 90px;
            height: 90px;
        }

        :first-child {
            background-image: url("../../assets/img/donators/radio-wuppertal.png");
            margin-right: 3vh;
        }

        :nth-child(2) {
            background-image: url("../../assets/img/donators/sparkasse.png");
        }

        :last-child {
            background-image: url("../../assets/img/donators/jackstaedt.png");
            margin-left: 3vh;
        }
    }

    p {
        text-align: center;
        color: $secondary;
    }
}